@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: 'Noto Sans TC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

body,
html {
  position: relative;
}

#root {
  width: 100%;
  height: 100%;
  white-space: pre-line;
}

:root {
  /* color-scheme: dark; */
}

.customNotification {
  background-color: #13131a;
  border-radius: 4px;
  width: 284px;
  height: 64px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
}

.ant-notification-bottomLeft {
  z-index: 1030;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 0;
}

.anticon svg {
  width: fit-content;
}

.ant-notification-notice-message {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
}
.ant-notification-notice-close {
  top: unset;
  right: 16px;
  color: #8b90a0;
}

.white-audio-plugin-body audio {
  width: 100%;
}
